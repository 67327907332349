.input {
  padding: 2px;
  box-sizing: border-box;
  background-color: var(--mark-background);
  border-radius: var(--border-radius-base);
  border: none;
  outline: none;

  &::placeholder {
    color: var(--gray-300);
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: unset;
  }

  &:not(:disabled):hover, &_focused {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--primary-300);
    background-color: var(--light);
  }

  &_error:not(.input_inactive) {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--danger-500) !important;
  }

  &:focus,
  &:focus:hover {
    box-shadow: none !important;
    background-color: unset
  }

  &_error:hover {
    background-color: var(--light);
  }

  &_inactive {
    background-color: rgba(220, 220, 220, 0.3);
    &:disabled {
      cursor: not-allowed;
      background-color: rgba(220, 220, 220, 0.3);
    }
  }
}
