.selfie_content {
  background-color: var(--gray-100);
  height: 550px;
  border: 1px solid var(--light);
  &_icon {
    height: 136px !important;
    width: 134px !important;
  }
  &_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &_error {
    border: 1px solid var(--danger-500);
  }
}

.modal_btns {
  &_wrapper {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }
  &_success {
    background-color: #56B74C !important;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.05) !important;
    border-radius: 2px !important;
    border: none !important;
    padding: 8px;
    color: var(--light) !important;
    transition: all var(--transition-time-base);
    height: 32px;
    &:hover {
      background-color: #408c38 !important;
    }
  }
}

.webcam {
  position: absolute;
  width: 100vw;
}

.alert {
  padding: 3px 8px;
  border-left-width: 0;
  border-radius: var(--border-radius-lg);
  font-size: 12px;
  color: var(--danger-500);
}

@media (max-width: 768px) {
  .selfie_content {
    height: 356px;
  }
}

@media (max-width: 400px) {
  .selfie_content {
    height: 256px;
  }
}
