.checkbox {
  font-size: 12px;
  line-height: 12px;
  padding: 2px;
  height: calc(1em + 4px);
  width: calc(1em + 1px);
  background-color: var(--mark-background);
  border-radius: 2px;
  justify-content: center;
  &:hover:not(.checkbox_error):not(.checkbox_disabled), &_focused {
    border: 1px solid var(--primary-300);
  }
  &_error:not(.checkbox_inactive) {
    border: 1px solid var(--danger-500);
  }
  &_disabled {
    cursor: not-allowed;
  }
  &_inactive {
    cursor: not-allowed !important;
    background-color: rgba(220, 220, 220, 0.3);
    &:hover:not(.checkbox_error):not(.checkbox_disabled), &_focused {
      border: none;
    }
  }
}
