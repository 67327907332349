.logo {
  height: 18.37px;
  margin: 0 16px 0 0;
  &_custom {
    height: 24px;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 12px;
    margin-left: 4px;
  }
}
