.select {
  width: 100%;
  font-size: 12px;
  line-height: 12px;

  .select__control {
    border-bottom: none;
    border: none;
    border-radius: var(--border-radius-base);
    cursor: pointer;
    background-color: var(--mark-background);
    box-shadow: none;
  }

  &:hover .select__control:not(:focus-within), &_focused .select__control:not(:focus-within) {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--primary-500);
    background-color: var(--light);
  }

  &_disabled {
    cursor: not-allowed;
    .select__control {
      background-color: var(--light) !important;
    }
  }

  &_error:hover:not(:focus-within) > .select__control {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--danger-500);
  }

  &_error:not(.select_inactive) > .select__control {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--danger-500);
  }

  &:focus-within .select__control{
    background-color: unset;
    box-shadow: none;
  }

  &_inactive {
    .select__control {
      background-color: rgba(220, 220, 220, 0.3) !important;
    }
  }

  .select__indicator {
    padding: 0 var(--space-sm) 0 0;
  }

  .input {
    padding: 1px 0;
    margin: 0;
  }
}
