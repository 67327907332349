.signature {
  &_tabs div:first-child {
    display: inline-flex !important;
  }
  &_content {
    border: 1px solid var(--gray-200);
    border-radius: 2px
  }
  &_image {
    max-width: 100%;
    max-height: 100%;
  }
  &_actionButtons {
    right: -8px;
  }
  &_dropZone {
    border-bottom: 1px solid var(--gray-200);
    max-height: 152px;
    min-height: 152px;
  }
  &_draw {
    border: 1px solid var(--gray-200);
  }
  &_choose {
    &_item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-200);
      }
    }
  }
}

.signature_upload_image {
  width: 2.5rem;
  height: 2.5rem;
}

.canvas_wrapper {
  padding: 16px 16px 8px 16px;
}

.canvas_clear_btn {
  position: absolute;
  top: -1.5rem;
  right: 0.5rem;
}

.modal_btns_wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

@media (max-width: 768px) {
  .canvas_wrapper {
    padding: 8px 0 0 0;
  }
  .canvas_clear_btn {
    position: absolute;
    top: -0.8rem;
    font-size: 11px;
    left: 50%;
    transform: translateX(-50%);
  }

  .modal_btns_wrapper {
    flex-direction: column;
    row-gap: 8px;
  }
}
