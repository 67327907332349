.sidebar {
  width: 356px !important;
  height: calc(100vh - var(--header-height) - var(--footer-height)) !important;
  right: 0 !important;
  .info {
    &_block {
      border-bottom: 1px solid var(--gray-200);
      .subject {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
    }
    &_column {
      max-width: 158px;
      p {
        margin: 0;
      }
    }
  }
  .popover {
    &_container {
      top: -8px !important;
      right: 30px !important;
      width: 270px;
    }
    &_arrow {
      position: absolute;
      top: 11px;
      right: -12px;
      transform: rotate(90deg);
    }
  }
  .tasks_wrapper {
    max-height: calc(100vh - 238px);
    height: calc(100vh - 238px);
  }
  .additional_info {
    & > p:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .declared_signer {
    max-width: 134px !important;
    width: 134px !important;
  }
}

.not_allowed {
  cursor: not-allowed;
}
