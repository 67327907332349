.footer {
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--footer-height);
  background-color: white;
  border-top: 1px solid #D6D6D6;
  padding: 8px 16px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional_actions {
  border-radius: 4px;
}

.darker {
  background-color: #000;
  opacity: .5;
  height: calc(100vh - var(--footer-height));
  width: 100vw;
  position: absolute;
  top: calc(0px - 100vh + var(--footer-height) - 1px);
  left: 0;
}

.decline_btn {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
}

@media screen and (max-width: 400px) {
  .footer_btn {
    width: 100%;
  }
  .footer {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .wrapper {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr auto;
  }
}
