.wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .signer {
    .index_label {
      background-color: var(--gray-500);
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      line-height: 13px;
      color: var(--light);
    }

    .name {
      color: var(--gray-500);
      font-size: 13px;
      max-width: 250px;
    }

    &:nth-child(n):not(:last-child) {
      &::after {
        content: '';
        background-color: var(--gray-200);
        height: 1px;
        width: 32px;
        margin: 0 16px 0 16px;
      }
    }

    &_active {
      .index_label {
        background-color: var(--primary-500);
      }
      .name {
        font-weight: 600;
        color: var(--text-default);
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    position: relative;
    .signer {
      .name {
        font-size: 11px;
        max-width: calc((100vw - 140px) / 2);
      }
      &:nth-child(n):not(:last-child) {
        &::after {
          width: 14px;
        }
      }
    }
  }
}
