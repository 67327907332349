.filename_title {
  max-width: calc(100% - 100px);
}

.divider {
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100vw;
  background-color: var(--gray-200);
}
