.menu_wrapper {
  height: 100vh;
  width: 100vw;
  transform: translateY(calc(-100% - 50px));
  position: absolute;
  z-index: 4;
}

.menu_wrapper.menu_active {
  transform: translateY(0%);
}

.menu_inner {
  width: 100%;
  background-color: var(--light);
  transform: translateY(-100%);
  transition: all .3s;
}

.menu_wrapper.menu_active .menu_inner {
  transform: translateY(0);
}

.darker {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
