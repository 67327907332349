$btn-color: #56b74c;

.btn_wrapper {
  position: fixed;
  top: calc(var(--header-height) + 8px);
  left: 8px;
  z-index: 2;
  .btn {
    &_api, &_set {
      background-color: $btn-color;
      border-color: $btn-color;
      color: var(--light);
    }
    &_api {
      opacity: .5;
      &:hover, &:focus, &.active {
        background-color: $btn-color;
        opacity: 1;
      }
      svg {
        fill: var(--light);
      }
    }
    &_set:hover {
      background-color: #45953c;
    }
  }
  .form_url {
    width: 50rem;
  }

  .base_url {
    input {
      font-weight: inherit;
    }
  }
}
