.envelope_wrapper {
  background-color: var(--gray-100);
  --mark-background: rgba(114, 168, 215, 0.3);
}

.wrapper_page p {
  margin-bottom: 16px;
  margin-top: 12px;
}

.wrapper_page {
  gap: 16px;
  position: relative;
}

.canvas_wrapper {
  flex-direction: column;
}

.hor_scroll {
  overflow: scroll hidden;
}

.content {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - var(--header-height) - 55px);
}

.page_skeletone {
  background-color: white;
  padding-top: 141.4%;
}

.placeholder_spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
}

.placeholder_spinner svg{
  height: 3rem;
  width: 3rem;
}

.placeholder_text {
  white-space: nowrap;
  text-align: center;
}

.mark {
  position: absolute;
  transform-origin: 0 0;
}

.mark_offset {
  transform: translate(-2px, -2px);
}

.marks_wrapper {
  height: calc(100% - 49px);
  width: 100%;
  position: absolute;
  top: 0;
}

.italic {
  font-style: italic;
}

.arial {
  font-family: 'Arial';
}

.helvetica {
  font-family: 'Helvetica';
}

.times {
  font-family: 'Times New Roman', Times, serif;
}

.text_elem {
  text-decoration: underline;
  color: red;
}

.pointer {
  height: 32px;
  background-color: var(--primary-500);
  position: fixed;
  color: var(--light);
  line-height: 15px !important;
  cursor: pointer;
  transition: top var(--transition-time-base);
  z-index: 1;
  transform: translateX(-100%)
}

.pointer_left {
  left: 0;
  transform: unset
}

.pointer_mobile {
  top: 0;
  height: 20px;
  position: absolute;
  transition: top var(--transition-time-base);
}

.pointer_disabled {
  cursor: not-allowed;
  background-color: var(--primary-300);
  border-color: var(--primary-400);
}

@media (max-width: 768px) {
  .content {
    padding: 8px;
    height: calc(100vh - var(--header-height) - 47px);
  }
}
