.webcam {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
  &_wrapper {
    position: absolute;
    position: absolute;
    width: 100%;
    height: calc(100% + 96px);
    top: -48px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  }
  &_btns {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 34px;
    align-items: center;
    &_item {
      cursor: pointer;
      height: 56px;
      width: 56px;
      background: #FFFFFF;
      box-shadow: 0px 2px 3px rgba(51, 51, 51, 0.25);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all var(--transition-time-base);
      &_main {
        height: 80px;
        width: 80px;
        &_icon {
          width: 32px !important;
          height: 29px !important;
        }
      }
      &_disabled {
        cursor: not-allowed;
        background: var(--gray-200);
      }
      &:hover:not(.webcam_btns_item_disabled) {
        background: var(--gray-100);
      }
    }
  }
  &_spinner {
    &_wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_inner {
      height: 100px;
      width: 100px;
    }
  }
}
