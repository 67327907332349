.phone_input {
  width: 225px;
  input {
    height: 36px;
  }
  &_code input {
    width: 225px;
    height: 36px;
    border-radius: 2px;
  }
}

.submit_btn {
  background-color: #56B74C;
  border: none;
  border-radius: 4px;
  padding: 0 24px;
  font-weight: 700;
  color: white;
  margin-left: 16px;
  &:disabled {
    background-color: #97d191;
    cursor: not-allowed;
  }
}

.newcode {
  &_wrapper {
    height: 18px;
    margin-top: 26px;
  }
  &_btn {
    color: #56B74C;
  }
}

@media screen and (max-width: 768px) {
  .phone_input {
    width: 100%;
    margin-bottom: 34px;
    &_code input {
      width: 100%;
    }
  }
  .submit_btn {
    height: 34px;
    margin-left: 0;
  }
  .newcode {
    &_wrapper {
      margin-top: 8px;
      display: flex;
      justify-content: center;
    }
  }
}
