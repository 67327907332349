.scale_wrapper {
  margin-left: 16px;
}
.item, .popover {
  min-width: 54px;
}

@media (max-width: 768px) {
  .scale_wrapper {
    position: absolute;
    bottom: 8px;
    margin-left: 0;
    margin-top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
}
