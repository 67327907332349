.text_mark {
  resize: none;
  background-color: var(--mark-background);
  border-radius: var(--border-radius-base);
  border: none;
  outline: none;
  white-space: pre;
  line-height: 1.2;
  overflow: hidden;

  &:focus {
    background-color: unset;
  }

  &:hover:not(:focus):not(:disabled), &_focused {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--primary-300);
    background-color: unset;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: unset;
  }

  &_error:not(:focus):not(.text_mark_inactive)  {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--danger-500);
  }

  &_inactive {
    &:disabled {
      background-color: rgba(220, 220, 220, 0.3);
    }
    &:hover, &_focused {
      border: none;
    }
  }
}
