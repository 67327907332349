.language_selector button {
  border-color: var(--gray-200) !important;
}

.language_selector div[class$='button_open'] button {
  border-color: var(--primary-500) !important;
}

@media (max-width: 768px) {
  .language_selector button {
    width: 200px !important;
    height: 40px;
    font-size: 16px;
  }

  .language_selector div[class$='container'] {
    width: 200px;
  }

  .language_selector div[class$='container'] span {
    font-size: 16px;
  }

  .language_selector div[class$='container'] li[class$='item'] {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .language_selector {
    width: 100% !important;
  }

  .language_selector div, .language_selector button {
    width: 100% !important;
  }

  .language_selector div[class$='container'] {
    width: 100%;
  }
}
