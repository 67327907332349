.header_wrapper {
  z-index: 5;
}

.header {
  align-items: center;
  top: 0;
  width: 100%;
  height: var(--header-height);
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  grid-template-rows: 32px 32px;
}

.actions_buttons_item {
  min-width: 283px;
}

.actions_menu_item, .download_button_item  {
  cursor: pointer;
  background-color: var(--light);
  border-bottom: 1px solid var(--gray-200);
}

.download_button_item  {
  width: 200px;
  margin-left: -8px;
}

.actions_menu_item:hover, .actions_menu_item:active, .download_button_item:hover, .download_button_item:active {
  background-color: var(--info-50);
}

.download_button_item {
  border-bottom: none;
  padding: 8px;
}

.hamburger_wrapper {
  display: none;
  margin: 0 7px 0 0;
  height: 16px;
  width: 18px;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.hamburger_item {
  height: 2px;
  width: 100%;
  background-color: #09121F;
}

.hamburger_item::before, .hamburger_item::after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: #09121F;
  position: absolute;
  transition: transform .2s;
}

.hamburger_item::before {
  top: 0px;
}

.hamburger_item::after {
  bottom: 0px;
}

.hamburger_item.active {
  background-color: var(--light);
}

.hamburger_item.active::before {
  transform: rotate(45deg) translate(6px, 5px);
}

.hamburger_item.active::after {
  transform: rotate(-45deg) translate(5px, -4px);
}

.info_btn {
  min-width: 32px !important;
}

.info_btn:focus {
  background-color: var(--light);
}

.info_btn:hover {
  background-color: var(--gray-100);
}

.info_btn_active:not(:active)  {
  background-color: var(--gray-200);
  box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.125);
}

.download {
  width: calc(100vw - 16px);
  height: 54px;
  display: flex;
}

@media (max-width: 768px) {
  .actions_buttons {
    display: none;
  }
  .hamburger_wrapper {
    display: flex;
  }
}

@media (max-width: 400px) {
  .download_button_item  {
    width: 100vw;
  }
}
