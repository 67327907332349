.complete_message {
  &_wrapper {
    height: calc(100vh - var(--header-height));
    justify-content: center;
  }
  &_inner {
    margin-top: 103px;
    width: 640px;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 20px 30px rgba(5, 119, 137, 0.15);
    border-radius: 4px;
    padding: 48px 115px;
    justify-content: center;
  }
}

.logo {
  height: 27px;
  &_custom {
    height: 36px;
  }
}

.icon_wrapper {
  height: 80px;
  width: 80px;
  background-color: #56B74C;
  border-radius: 50%;
  justify-content: center;
  &_decline {
    background-color: #B94A48;
  }
}

.result_message {
  font-size: 20px;
  text-align: center;
}

.back_btn {
  padding: 11px 24px !important;
  color: #56B74C;
  border: 1px solid #56B74C;
  border-radius: 4px;
  background-color: var(--light);
  box-shadow: none;
}

@media screen and (max-width: 640px) {
  .complete_message {
    &_wrapper {
      padding: 16px;
      overflow-y: scroll;
      overflow-x: hidden;
      justify-content: start;
    }
    &_inner {
      width: 100%;
      padding: 48px 50px;
    }
  }
}
