.signature {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform-origin: 0 0;
  outline: none;
  cursor: pointer;
  &_mark {
    background-color: var(--light);
    appearance: none;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--primary-300);
    cursor: pointer;
    &:hover, &_focused {
      appearance: none;
      -webkit-appearance: none;
      box-shadow: inset 0 0 0 1px var(--primary-600);
    }
    &_inactive {
      cursor: not-allowed;
      box-shadow: inset 0 0 0 1px var(--gray-300);
      .signature_inner {
        background-color: rgba(220, 220, 220, 0.3);
      }
      &:hover, &_focused {
        box-shadow: inset 0 0 0 1px var(--gray-300);
      }
    }
  }
  &_img {
    overflow: hidden;
  }
  &_inner {
    height: 100%;
    width: 100%;
    background-color: rgba(114, 168, 215, 0.3);
  }
  &_content_wrapper {
    transform-origin: 50% 0;
  }
  &_inactive {
    cursor: not-allowed;
  }
}

.item_action {
  cursor: pointer;
  padding: 1rem;
  background-color: var(--light);
  border-bottom: 1px solid var(--gray-200);
  &:hover {
    background-color: var(--info-50);
  }
  &:active {
    background-color: var(--primary-600);
    color: var(--light)
  }
}
